'use client'
import PageLoader from "@/ui-components/atoms/PageLoader";
import { useEffect } from "react";
import auth from "@/utils/auth";
import { useRouter } from "next/navigation";
import { fetchProfileDetails } from "@/redux/slices/profile";
import { checkProfileDetails } from "@/apis";


// This page only renders when the app is built statically (output: 'export')
export default function RootPage() {
  const router = useRouter();
  useEffect(() => {
    router.prefetch(`/en`)
    const appInitialization = async () => {
      await auth();
      const resultData = await checkProfileDetails();
      console.log(resultData);
      if (resultData.provider === "CRMLS" && resultData?.profession === "") {
        router.push("/sign-up");
      } else {
        router.push("/en");
      }
    };
    //get userdata
    appInitialization();
  }, []);
  return <PageLoader/>
}
